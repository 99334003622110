
img, video {
  width: 100%;
}

.faqSection {
  margin: $spacing*2 0;
}

p.MuiTypography-root {
  max-width: 100%;
}


.MuiPaper-root {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  padding-bottom: .25em;
  padding-top: .25em;
  border-radius: 0;


  .MuiAccordionSummary-content {

    p {
      font-weight: bold;

    }
  }
}
