// Colors
$primary: #EF7B10;
$primary-light: #FFA048;
$secondary: #1C2246;

// Spacing base
$spacing: 8px;

// Breakpoints
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

// Navigation
$footerHeight: 56px;
$headerHeight: 80px;
$navHeight: $footerHeight - $headerHeight;

//Sections, Pages, Views
@import "components/section";


//Components
@import "components/menu";
@import "components/login";
@import "components/faq";



