
.popUp {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .wrapper {
    width: 90%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;

    .close {
      display: flex;
      flex-direction: row-reverse;
    }

    .content {
      height: 500px;
      overflow: scroll;
    }
  }
}