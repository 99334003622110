section {
  padding: $spacing 0;

  .MuiContainer-root {
    padding-top: 2*$spacing;
    padding-bottom: 2*$spacing;
  }
}


//firstView

section {

  .firstView {
  }

  .loginContainer, .faqContainer, .contactForm {
    min-height: 75vh;
  }

  .faqContainer, .contactForm {
    background: $secondary;

    .contactFormWrapper {
      padding-bottom: 5em !important;
      padding-top: 1em;
    }
  }
}

@media only screen and (min-width: $md) {
  section {
    min-height: 100vh;
    padding: 0;

    .contentContainer {
      padding-bottom: $footerHeight + 2*$spacing;
    }

    .firstView {
      .loginContainer {
        height: 100vh;
      }
    }

    .faq {

      .faqContainer {
        min-height: 100vh;
      }
    }

    .zuweiser {
      .contactForm {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 900px) {
  section .zuweiser .contactForm .contactFormWrapper {
    padding-bottom: 1rem;
  }
}