.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: $spacing*4;
  padding-top: $spacing*4;

  .loginPatient, .loginZuweiser {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    width: 100%;
    padding: $spacing*4;
  }

  .loginPatient {
    background: $primary;
  }

  .loginZuweiser {
    background: $primary-light;
  }
}

@media only screen and (min-width: $md) {
  .loginContainer {
    padding-bottom: 0;
    padding-top: 0;
  }
}